
@import "../../bootstrap/variables.less";

@body-bg:					#8ACDF4;

@text-color:				#000;
@link-color:				#0380FC;
@link-hover-color:			#0380FC;
@link-hover-decoration:		underline;

@font-family-base:			Comic Sans MS;
@font-size-base:			11px;
@line-height-base:			1.25;

@font-size-h1:				34px;
@headings-small-color:		#fff;

@thumb-width:				492px;
@thumb-height:				368px;
@thumb-margin-top:			0;
@thumb-margin-right:		0;
@thumb-margin-bottom:		0;
@thumb-margin-left:			0;
@thumb-border-width:		1px;
@thumb-border:				1px solid #fff;
@thumb-hover-border:		1px solid #fff;

@zindex-popup:				100;
@tooltip-arrow-width:		5px;
@tooltip-bg:				#444;
@popup-text-color:			#fff;
@popup-font-weight: 		bold;

@pager-border:					none;
@pager-padding:					2px;
@pager-text-decoration:			underline;
@pager-color:					#0380FC;
@pager-color-active:			#000;
@pager-text-decoration-active:	underline;
@pager-hover-bg:				transparent;
@pager-font-weight-active:		normal;
@pager-font-size:				16px;
@pager-font-size-mobile:		24px;

@social-links-margin:			5px 0;

@sites-list-font-size:				15px;
@sites-list-color:					#000;
@sites-list-title-margin-bottom:	5px;
@sites-list-title-size:				18px;
@sites-list-title-weight:			bold;
@sites-list-title-decoration:		none;

@sites-footer-traffictrade-size:	12px;
@sites-footer-traffictrade-margin:	10px 0;
@sites-footer-margin:				10px 0 0;
@sites-footer-size:					12px;
@sites-footer-color:				#000;

@import "common/bootstrap.less";
@import "../../common/utils.less";
@import "../../common/flags-small.less";
@import "../../common/flags.less";
@import "common/pagination.less";
@import "common/modules.less";
@import "common/sites_list.less";
@import "common/sites_footer.less";
@import "common/popup.less";
@import "common/mozaique_simple.less";

a {
	text-decoration: underline;
	cursor: pointer;
}
.white {
	color: #fff;
}
.blue {
	color: #0380FC;
}
h1 {
	text-align: center;
	margin: 0 auto;
	padding: 0;
	.text-nowrap {
		display: inline-block;
		vertical-align: top;
	}
	.slogan {
		font-size: 14px;
		line-height: 20px;
		display: inline-block;
		text-align: left;
	}
}
.links {
	text-align: center;
	font-size: 18px;
	a.nolink {
		text-decoration: none;
		color: #000;
	}		
}
#language-switcher {
	position: absolute;
	right: 10px;
	top: 0;
}